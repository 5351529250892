import chars4 from '../../assets/img/frame4chars.webp';
import chars4m from '../../assets/img/chars4mobile.webp';

import './Frame1.css';

function Frame4() {
    return(
        <div className="bg-frame4-bg h-screen lg:h-[1024px] relative bg-cover bg-center hidden md:block">
        <h1 className="absolute left-0 right-0 mx-auto top-12 lg:top-24 font-ROBOTECH gameDetails">X-METAPOL GAME DETAILS</h1>
        <img src={chars4} alt="" className="hidden lg:block absolute bottom-0"/>
        <img src={chars4m} alt="" className="lg:hidden absolute bottom-0 h-[80%]"/>    </div>
    );
}

export default Frame4;