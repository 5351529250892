import "./Tokenomics.css";
import cloud from '../assets/img/cloud_part.png'

function Partners() {
    return(
        <div className="flex flex-col items-center bg-7 bg-cover bg-[#000625] bg-blend-color-dodge  py-[10%] relative" id="partners">
            <h1 className="font-ROBOTECH text-4xl text-white mb-16">WE WORK WITH THE BEST <br/>PARTNERS & BACKERS</h1>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-10">
                <div className='mb-8 lg:mb-0 mr-0 lg:mr-20 bg-backers hover:bg-backersH  trans w-[354px] h-[323.25px] '></div>
                <div className='bg-backers2 hover:bg-backers2H w-[354px] h-[323.25px] trans '></div>
                <div className='bg-backers3 hover:bg-backers2H w-[354px] h-[323.25px] trans '></div>
                <div className='bg-backers4 hover:bg-backers2H w-[354px] h-[323.25px] trans '></div>
                <div className='bg-backers5 hover:bg-backers2H w-[354px] h-[323.25px] trans '></div>
                <div className='bg-backers6 hover:bg-backers2H w-[354px] h-[323.25px] trans '></div>
                <div className='bg-backers7 hover:bg-backers2H w-[354px] h-[323.25px] trans '></div>
                <div className='bg-backers8 hover:bg-backers2H w-[354px] h-[323.25px] trans '></div>





            </div>
            <img src={cloud} alt="" className="absolute bottom-0 right-0 mix-blend-color-dodge hidden lg:block"/>
            <img src={cloud} alt="" className="absolute scale-x-[-1] left-0 bottom-0 mix-blend-color-dodge hidden lg:block"/>

        </div>
    );
}
    
export default Partners;