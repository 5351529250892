import spinner from '../assets/img/bigdickFisherman.png'


function Loading() {
    return(
        <div className="bg-hero-pattern w-full h-screen flex items-center justify-center">
                <img src={spinner} alt="" />
        </div>
        );
}


export default Loading;