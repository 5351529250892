import fLogo from "../assets/img/footerLogo.png";
import ins from "../assets/img/instagram.png";
import face from "../assets/img/facebook.png";
import twitter from "../assets/img/twitter.png";

import "./Game/Frame1.css";

function Footer() {
  return (
    <div className="flex flex-col">
      <div className="bg-[#0A003F] flex lg:flex-row flex-col py-20">
        <div className="flex items-center justify-center basis-[30%] mb-10 text-center lg:mb-0">
          <img src={fLogo} alt="" />
        </div>
        <div className="text-white font-poppins font-light basis-[25%] lg:text-left h-full mb-10 text-center lg:mb-0">
          <h1 className="mb-6 text-lg font-bold">Products</h1>

          <h2 className="font-medium">Pancakeswap</h2>
          <h2 className="font-medium">Uniswap</h2>
          <h2 className="font-medium">Whitepaper</h2>
          <h2 className="font-medium">Roadmap</h2>
          <h2 className="font-medium">Tokenomics</h2>
          <h2 className="font-medium">FAQ</h2>
        </div>
        <div className=" text-white font-poppins font-light basis-[25%] lg:text-left h-full mb-10 text-center lg:mb-0">
          <h1 className="mb-6 text-lg font-bold">Need Help?</h1>

          <h2 className="font-medium">Contact Us</h2>
          <h2 className="font-medium">Discord</h2>
          <h2 className="font-medium">Medium</h2>
          <h2 className="font-medium">Reddit</h2>
          <h2 className="font-medium">Privacy</h2>
          <h2 className="font-medium">Terms of Service</h2>
        </div>
        <div className="basis-[20%] flex items-center flex-col justify-center">
          <h1 className="text-2xl text-white font-poppins font-semibold">
            FOLLOW US
          </h1>
          <div className="flex flex-row">
            <a href="" target="_" rel="noreferrer">
            <img src={twitter} alt="" className="h-[36px] w-[36px] mt-2 mr-2" />
            </a>
            <a href="" target="_" rel="noreferrer"> <img src={face} alt="" className="h-[36px] w-[36px] mt-2 mr-2" /></a>
            <a href="" target="_" rel="noreferrer"> <img src={ins} alt="" className="mt-0 mr-2" /></a>
            
            
          </div>
        </div>
      </div>
      <div className="h-[100px] flex items-center justify-center bg-[#000625]">
        <h1 className="font-ROBOTECH text-lg text-white">
          Copyright © 2023 Xmetapol
        </h1>
      </div>
    </div>
  );
}

export default Footer;
