import chars3 from '../../assets/img/frame3chars.webp';
import chars3m from '../../assets/img/chars3mobile.webp';
import Carousel from '../../components/carousel/Carousel'

import './Frame1.css';

function Frame3() {


    const slides = [
        {
            title: "EXPRESS YOURSELF",
            description: "Be free in style while choosing the features and clothes of your avatar. Furnish your places. We are regularly updating our Clothing and Furniture Stores to give you better and fashionable service."
        },
        {
            title: "BE PROFESSIONAL",
            description: "Basic professions we trust to build a strong society. These professions meet the needs of society. We earn our crust from the 'soil': Be Farmer or Forester!"
        },
        {
            title: "ACHIEVE & COMPLETE",
            description: "All we need is success. In the way of success, Polity invites you to accomplish the various quests in accordance with your profession."
        },
        {
            title: "BE SOCIAL!",
            description: "You are not alone unless you want to be one. You can chat with other players through Chat Balloons or the private chat on your 'IDPhone'"
        },
        {
            title: "UNCONDITIONALLY MERCHANT!",
            description: "In-game trading is fun: Buy and sell items or barter your goods with other avatars' goods."
        },
        {
            title: "EXPLORE!",
            description: "Customized platforms by real players waiting for their visitors or future citizens. Come and visit them to see many personal 'dreamlands'!"
        }
    ];



    return(
        <div className='bg-[#000625]'>
            <div className="bg-frame3-bg h-screen lg:h-[1024px] relative bg-cover hidden md:block">
                <h1 className="absolute left-0 right-0 mx-auto top-12 lg:top-24 font-ROBOTECH gameDetails">X-METAPOL GAME DETAILS</h1>
                <img src={chars3} alt="" className="hidden lg:block absolute bottom-0"/>
                <img src={chars3m} alt="" className="lg:hidden absolute bottom-0"/>
            </div>
            
            
            <div className='bg-2 flex w-full md:hidden min-h-[450px] no-scrollbar cursor-grab justify-center py-[10%] bg-blend-color-dodge bg-[#000625]'>
                <Carousel slides={slides} indicators width={300} />
            </div>
        </div>
    );
}

export default Frame3;