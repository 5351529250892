import './NFT.css';
import heart from '../assets/img/shape.png'
import placeABid from '../assets/img/placeabid.png'

function NFT() {
    return(
        <div className="rounded-3xl bg-NFT1  w-[344px] h-[522px] relative mr-0 lg:mr-12 mb-10 lg:mb-0 bg-[#000625] bg-blend-color-dodge">
            
            <div className="absolute bottom-0 w-full blurB h-[160px]">
                <div className="flex justify-between p-6">
                    <h2 className="text-white font-poppins font-bold">NFT - ...</h2>
                    <img src={heart} alt=""  className="w-[24px] h-[21px] cursor-pointer"/>
                </div>
            </div>
            <div className="absolute left-0 right-0 mx-auto -bottom-8 cursor-pointer bg-placeABid w-[196px] h-[60px] flex items-center justify-center">
                <h1 className="text-[#070032] font-inter font-extrabold">PLACE A BID</h1>
            </div>
           
        </div>
    ); 
}

export default NFT;