import marker from '../assets/img/roadmapMarker.png';

function Roadmap() {
    return(
        <div className="bg-5 bg-blend-color-dodge bg-cover bg-[#000625] flex flex-col lg:flex-row lg:justify-between p-[12%] gap-12" id="roadmap">
            <div className="basis-[40%]">
                <h1 className="font-ROBOTECH capitalize text-white text-5xl text-left mb-8">a look into roadmaps PHASES</h1>
                <h3 className="text-sm text-[#B9B3AA] text-left mb-8 hidden md:block">The key points of the roadmap for the development and launch of a new game. The roadmap consists of four phases that include feedback gathering, feature introductions, developing the game's ecosystem, integrating it with blockchain technology, and reaching a target market capitalization of 100 million. The final phase is game launch, and the roadmap is designed to ensure the game's success.</h3>
                <div className="bg-placeABid w-[196px] h-[60px] text-center text-xl font-bold flex items-center justify-center">ROADMAP</div>
            </div>
            
            <div className="basis-[50%] border-l-4 border-solid border-[#06CBC7] text-[#B9B3AA] pl-10 relative ">
                <img src={marker} className="absolute left-0 top-32" alt="" />
                <div className="bg-[#16033C] text-left py-8 px-10 mb-4">
                    <h1 className="text-white font-ROBOTECH text-3xl mb-6">PHASE I</h1>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Gathering feedback from beta
                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Seed and private rounds opening                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Partnership onboarding                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        New game features introduction                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        IDO - Public sale
                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Listing on CMC/CG
                        </h1>
                   </div>
                </div>
                <div className="bg-[#16033C] text-left py-8 px-10 mb-4">
                    <h1 className="text-white font-ROBOTECH text-3xl mb-6">PHASE II</h1>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Publishing the game trailer
                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        New profession Area addition (each 3 month)
                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Setting up Blockchain servers for the game
                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Developing the ecosystem for the game
                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Starting the process of integrating the game into the Blockchain
                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Target 100m mcap
                        </h1>
                   </div>
                   <div className="flex items-center gap-4">
                    <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                        <h1 className="w-full">
                        Contact to CEX(top I-II)
                        </h1>
                   </div>
                </div>
                <div className="bg-[#16033C] text-left py-8 px-10 mb-4">
                    <h1 className="text-white font-ROBOTECH text-3xl mb-6">PHASE III</h1>
                   <div className="flex items-center gap-4">
                   <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                    <h1 className="w-full">
                    Preparation of NFT features
                    </h1>
                   </div>
                   <div className="flex items-center gap-4">
                   <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                    <h1 className="w-full">
                    NFT/LAND sales
                    </h1>
                   </div>
                   <div className="flex items-center gap-4">
                   <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                    <h1 className="w-full">
                    Opening in-game profession groups
                    </h1>
                   </div>
                   <div className="flex items-center gap-4">
                   <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                    <h1 className="w-full">
                    Implementation payment on a different server
                    </h1>
                   </div>
                   <div className="flex items-center gap-4">
                   <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                    <h1 className="w-full">
                    Introduction of new in-game characters
                    </h1>
                   </div>
                   <div className="flex items-center gap-4">
                   <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                    <h1 className="w-full">
                    Reviewing all updates
                    </h1>
                   </div>
                </div>
                <div className="bg-[#16033C] text-left py-8 px-10">
                    <h1 className="text-white font-ROBOTECH text-3xl mb-6">PHASE IV</h1>
                   <div className="flex items-center gap-4">
                   <span className="bg-black h-4 w-4 rounded-full border-solid border-[2px] border-[#807769]"></span>
                    <h1 className="w-full">
                    Game Launch
                    </h1>
                   </div>
                </div>
            </div>
        </div>
    );
}

export default Roadmap;