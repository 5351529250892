import { useState } from "react";
import '../sections/Hero.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <nav className="bg-transparent">
        <div className="max-w-7xl mx-auto px-8">
          <div className="flex items-center justify-between h-16">
            <div className="w-full justify-center flex items-center font-semibold mt-10">
   
              <div className="hidden md:block">
                <div className="ml-12 flex items-center font-ROBOTECH text-xl font-normal">
                  <a
                    href="https://www.xmetapol.com/xmetapol_whitepaper.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-4 lg:mr-10 headerText cursor-pointer font-ROBOTECH"
                  >
                    WHITEPAPER
                  </a>
                  <a
                    href="https://www.xmetapol.com/xmetapol_pitchdeck.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-4 lg:mr-10 headerText cursor-pointer"
                  >
                    PITCHDECK
                  </a>
                  <a
                    href="https://www.xmetapol.com/xmetapol_roadmap.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-4 lg:mr-10 headerText cursor-pointer"
                  >
                    ROADMAP
                  </a>
                  <a
                    href="https://www.xmetapol.com/xmetapol_tokenomics.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-4 lg:mr-10 headerText cursor-pointer"
                  >
                    TOKENOMICS
                  </a>
                  <a
                    href="https://www.xmetapol.com/gameDeckModified.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-4 lg:mr-10 headerText cursor-pointer"
                  >
                    GAME DECK
                  </a>

                  <a
                    href="https://www.mexc.com/tr-TR/exchange/XMP_USDT?_from=header"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-4 lg:mr-10 text-white cursor-pointer bg-[#070032ba] px-4 rounded-md"
                  >
                   Buy Now at MEXC
                  </a>
                </div>
              </div>
            </div>
            <div className="block">
              <div className="ml-4 flex items-center md:ml-6"></div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                className="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
              >
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="h-8 w-8"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden">
            <div className="flex flex-row items-center justify-center font-semibold">
              <div className="flex flex-col items-start mr-4 flex-nowrap font-ROBOTECH">
                  <a
                    href="https://www.xmetapol.com/xmetapol_whitepaper.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-0 lg:mr-10 headerText cursor-pointer"
                  >
                    WHITEPAPER
                  </a>
                  <a
                    href="https://www.xmetapol.com/xmetapol_pitchdeck.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-0 lg:mr-10 headerText cursor-pointer"
                  >
                    PITCHDECK
                  </a>
              </div>
              <div className="flex flex-col items-start font-poppins">
                  <a
                    href="https://www.xmetapol.com/xmetapol_roadmap.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-4 lg:mr-10 headerText cursor-pointer"
                  >
                    ROADMAP
                  </a>
                  <a
                    href="https://www.xmetapol.com/xmetapol_tokenomics.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-4 lg:mr-10 headerText cursor-pointer"
                  >
                    TOKENOMICS
                  </a>
                  <a
                    href="https://www.xmetapol.com/gameDeckModified.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="mr-4 lg:mr-10 headerText cursor-pointer"
                  >
                    GAME DECK
                  </a>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}

export default Header;
