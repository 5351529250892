import './App.css';
import Characters from './sections/Characters';
import Hero from './sections/Hero';
import NFTMetapoland from './sections/NFTMetapoland';
import Partners from './sections/Partners';
import Roadmap from './sections/Roadmap';
import Tokenomics from './sections/Tokenomics';
import Utility from './sections/Utility';
import Team from './sections/Team';
import Footer from './sections/Footer';
import Frame1 from './sections/Game/Frame1';
import Frame2 from './sections/Game/Frame2';
import Frame3 from './sections/Game/Frame3';
import Frame4 from './sections/Game/Frame4';
import Frame5 from './sections/Game/Frame5';

import { useEffect, useState } from 'react';
import Loading from './sections/Loading';





function App() {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 200)
  }, [])
  return (
    <>
      {loading === false ? 
      (
        <div className="App">
            <Hero></Hero>
            <Frame1></Frame1>
            <Frame2></Frame2>
            <Frame3></Frame3>
            <Frame4></Frame4>
            <Frame5></Frame5>
            <NFTMetapoland></NFTMetapoland>
            <Characters></Characters>
            <Utility></Utility>
            <Tokenomics></Tokenomics>
            <Roadmap></Roadmap>
            <Team></Team>
            <Partners></Partners>
            <Footer></Footer>
  
        </div>
      ) : <Loading></Loading>}
    </>
  )
}

export default App;
