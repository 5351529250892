import boy from "../../assets/img/frame1boy.webp";
import girl from "../../assets/img/frame1girl.webp";
import clouds from "../../assets/img/frame1clouds.webp";
import "./Frame1.css";
import frame1Obj1 from '../../assets/img/frame1Obj1.png'
import frame1Obj from '../../assets/img/frame1Obj.png'


function Frame1() {
  return (
    <div className="scale-100">
      <div
        className="bg-frame1-bg bg-center lg:bg-left bg-cover h-[1024px] relative overflow-x-clip lg:block hidden "
        id="frame1"
      >
        <div className="absolute left-0 right-0 mx-auto top-14 w-full lg:w-[25%]">
          <h1 className="logoText1 font-ROBOTECH">XMETAPOL GAME</h1>
        </div>
        <div className="bg-frame1-board absolute right-8 lg:right-20 bottom-0 h-[882px] w-[339px] z-10 flex flex-col">
          <a href="#metapoland" className="w-[300px] h-[80px] mt-12"></a>
          <a href="#characters" className="w-[300px] h-[80px]"></a>
          <a href="#trailer" className="w-[300px] h-[80px]"></a>
          <a href="#why" className="w-[300px] h-[80px]"></a>
          <a href="#tokenomics" className="w-[300px] h-[80px]"></a>
          <a href="#roadmap" className="w-[300px] h-[80px]"></a>
          <a href="#team" className="w-[300px] h-[80px]"></a>
          <a href="#partners" className="w-[300px] h-[80px]"></a>
        </div>

        <img
          src={boy}
          alt=""
          className="hidden lg:block absolute left-0 right-6 mx-auto top-72"
        />
        <img
          src={clouds}
          alt=""
          className="h-auto w-auto max-w-[1564px] absolute object-cover -bottom-64 lg:-bottom-[200px] pointer-events-none -left-32 lg:left-0 lg:right-0 z-10"
        />
        <img
          src={girl}
          alt=""
          className="hidden lg:block w-[505px] h-[666px] absolute bottom-20"
        />
      </div>
      <div className="md:hidden bg-1 bg-blend-color-dodge flex flex-col items-center justify-center py-[20%] relative">
          <img src={frame1Obj1} className="absolute top-0 left-0 mix-blend-color-dodge h-[543px]" alt="" />
          <img src={frame1Obj} className="absolute bottom-0 right-0 mix-blend-color-dodge h-[446px]" alt="" />


          <h1 className="font-ROBOTECH text-white text-5xl mb-10">X-METAPOL GAME DETAILS</h1>

          <div className="bg-frame1-fr h-[300px] bg-no-repeat w-[222px] flex items-center justify-center mb-6">
            <h1 className="text-white font-poppins text-sm px-2">X-MetaPol is a social RPG in a user-generated world, where people can rule their own country or become citizens of other players' countries.</h1>
          </div>
          <div className="bg-frame1-fr h-[300px] bg-no-repeat w-[222px] flex items-center justify-center mb-6">
            <h1 className="text-white font-poppins text-sm px-2">This world offers you the opportunity to shape your own utopia with real players. Establish your country, customize it, and set your own rules, or find a profession you are fond of as a citizen.</h1>
          </div>
          <div className="bg-frame1-fr h-[300px] bg-no-repeat w-[222px] flex items-center justify-start">
            <h1 className="text-white font-poppins text-sm px-2"> Both choices will lead you to different paths. Alongside being a citizen, basic professions (such as farmer, forester, etc.) are designed to create a purpose-based atmosphere; in this way, players can internalize the profession on their way to being president. </h1>
          </div>
      </div>
    </div>
  );
}

export default Frame1;
