
const CarouselItem = ({ slide, stopSlide, startSlide }) => {

  return (
    <div className="carousel-item bg-[#000625]" onMouseEnter={stopSlide} onMouseOut={startSlide}>
         <div className='bg-[#000625] bg-frame3-fr bg-blend-color-dodge h-[357px] min-w-[276px] mr-4 flex items-center flex-col justify-center'>
              <h1 className='text-white font-ROBOTECH text-3xl mb-4'>{slide.title}</h1>
              <h2 className='text-white font-poppins text-sm max-w-[80%]'>{slide.description}</h2>
          </div>
    </div>
  )
}

export default CarouselItem