import world from "../assets/img/world.png";
import chars from "../assets/img/chars.webp";
import logo from "../assets/img/logo.webp";
import stone1 from "../assets/img/stone1.webp";
import stone2 from "../assets/img/stone2.webp";
import videoAnim from '../assets/img/xMetapol_Trim.mp4'
import Header from "../components/Header";
import "./Hero.css";
import { useState} from 'react';

function Hero() {


  const [ xvideo, setVideo ] = useState("hidden");
  const [ isOpened, setIsOpened ] = useState(false);



  function handleClick() {
    var elem = document.getElementById("videoplayer");

    elem.removeAttribute('controls');

    setIsOpened(true)

    if(!isOpened) {
       
    setVideo("block");

    if (elem.requestFullscreen) {
      elem.removeAttribute('controls');
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.removeAttribute('controls');
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.removeAttribute('controls');
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { 
      elem.removeAttribute('controls');
      elem.msRequestFullscreen();
    }

    setTimeout(() => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        elem.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
      setVideo("hidden")

    }, 8000);
    
    } else {
      return
    }
  } 


  return (
    <div className="h-screen bg-hero-pattern lg:bg-cover bg-no-repeat overflow-hidden">
      {/* ---------------------- Header --------------------- */}
      {/* <div className=" text-white absolute top-3 left-0 right-0 mx-auto pt-10 font-ROBOTECH leading-10">
        <div className="flex flex-row justify-center mx-[20%]">
          <div className="flex flex-col lg:flex-row">
            <a href="https://www.xmetapol.com/xmetapol_whitepaper.pdf" target="_blank" rel="noreferrer" className="mr-4 lg:mr-10 headerText cursor-pointer">WHITEPAPER</a>
            <a href="https://www.xmetapol.com/xmetapol_pitchdeck.pdf" target="_blank" rel="noreferrer" className="mr-4 lg:mr-10 headerText cursor-pointer">PITCHDECK</a>
          </div>
          <div className="flex flex-col lg:flex-row">
            <a href="https://www.xmetapol.com/xmetapol_roadmap.pdf" target="_blank" rel="noreferrer" className="mr-4 lg:mr-10 headerText cursor-pointer">ROADMAP</a>
            <a href="https://www.xmetapol.com/xmetapol_tokenomics.pdf" target="_blank" rel="noreferrer" className="mr-4 lg:mr-10 headerText cursor-pointer">TOKENOMICS</a>
          </div> 
        </div>
      </div> */}

      <Header></Header>

      {/* -------------------- Logo + subtext------------------- */}
      <div className="max-w-[90vw] lg:max-w-[30vw] absolute left-0 right-0 mx-auto top-[20%] pointer-events-none">
        <h1 className="font-ROBOTECH text-white text-2xl">WELCOME TO</h1>
        <img src={logo} alt=""  />
        <h3 className="font-poppins text-[0.8rem] text-white ">
          Welcome to X-Metapol: (Metaverse) is the most revolutionary and
          advanced version of the system and is the game project that will bring
          a big change on both Mobile and PC. Explore, trade in the virtual
          world owned by its users and meet new friends.
        </h3>
      </div>
      {/* ---------------------------- imgs ------------------------ */}

      <picture className="pointer-events-none">
        <img
          src={chars}
          alt=""
          className="absolute bottom-[120px] lg:bottom-[170px] left-0 right-0 mx-auto h-[210px] animateY"
        />

        <img
          src={world}
          alt=""
          className="absolute bottom-0 left-0 right-0 mx-auto w-[35rem]"
        />

        {/* ------------------------ left stones ----------------- */}
        <img
          src={stone1}
          alt=""
          className="absolute hidden lg:block top-[6rem] left-[2rem] stone1 h-32 rotate-45"
        />
        <img
          src={stone1}
          alt=""
          className="absolute hidden lg:block bottom-[3rem] left-[8rem] h-24 stone3"
        />
        <img
          src={stone2}
          alt=""
          className="absolute hidden lg:block bottom-[26rem] left-[12rem] h-20 stone4"
        />
        <img
          src={stone2}
          alt=""
          className="absolute hidden lg:block bottom-[18rem] left-[20rem] h-20 stone5"
        />
        <img
          src={stone2}
          alt=""
          className="absolute hidden lg:block bottom-[12rem] left-[8rem] h-20 stone2"
        />

        {/* --------------------------- Right Stones --------------------- */}

        <img
          src={stone1}
          alt=""
          className="absolute hidden lg:block top-[2rem] right-[8rem] h-32 stone5"
        />
        <img
          src={stone1}
          alt=""
          className="absolute hidden lg:block bottom-[2rem] right-[8rem] h-28 stone2 rotate-270"
        />
        <img
          src={stone2}
          alt=""
          className="absolute hidden lg:block bottom-[10rem] right-[28rem] h-16 stone1"
        />
        <img
          src={stone2}
          alt=""
          className="absolute hidden lg:block bottom-[15rem] right-[16rem] h-20 stone3"
        />
        <img
          src={stone2}
          alt=""
          className="absolute hidden lg:block bottom-[24rem] right-[24rem] h-20 stone4"
        />
      </picture>
      {/* -------------------- discover ---------------- */}
     <div>
     <a href="#frame1" onClick={handleClick}  className="max-w-[140px] bg-[#004dbd] px-4 py-2 font-bold text-white hover:bg-[#00275F] hover:cursor-pointer absolute bottom-[25px] lg:bottom-[60px] left-0 right-0 mx-auto discoverShadow font-poppins">
        Discover
      </a>
     </div>
     
      
        <video  muted autoPlay poster="" disablePictureInPicture id="videoplayer" className={xvideo}>
          <source src={videoAnim}  type="video/mp4" />
        </video>
        
    </div>
  );
}

export default Hero;
