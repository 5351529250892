import chars5 from '../../assets/img/frame5chars.webp';
import amrobotu from '../../assets/img/paspas.webp';
import './Frame1.css';
import fuckText from '../../assets/img/Frame49.png'

function Frame5() {
    return(
        <div className="bg-frame5-bg h-[1024px] relative bg-cover bg-center hidden md:block">
        <div className="pt-20 overflow-x-auto w-full lg:w-auto flex justify-center" >
            <img src={fuckText} alt="" className="max-w-[1400px]"/>
        </div>
        <img src={chars5} alt="" className="hidden lg:block absolute bottom-10 right-0 left-0 mx-auto"/>
        <img src={amrobotu} alt="" className="lg:hidden absolute bottom-10 right-0 left-0 mx-auto"/>
    </div>
    );
}

export default Frame5;