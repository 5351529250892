import cchars from '../assets/img/charactersC.webp';
import char1 from '../assets/img/c1.webp';
import char2 from '../assets/img/c2.webp';
import char3 from '../assets/img/chars3.webp';
import char4 from '../assets/img/c4.webp';
import char5 from '../assets/img/c5.webp';
import char6 from '../assets/img/c6.webp';
import char7 from '../assets/img/c7.webp';
import char8 from '../assets/img/c8.webp';
import char9 from '../assets/img/c9.webp';
import char10 from '../assets/img/c10.webp';
import char11 from '../assets/img/c11.webp';
import char12 from '../assets/img/c12.webp';

import "./Hero.css";

import { useEffect} from 'react'


function Characters() {

    useEffect(() => {
        const slider = document.querySelector('#parent');
        let mouseDown = false;
        let startX, scrollLeft;
        
        let startDragging = function (e) {
          mouseDown = true;
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        };
        let stopDragging = function (event) {
          mouseDown = false;
        };
        
        slider.addEventListener('mousemove', (e) => {
          e.preventDefault();
          if(!mouseDown) { return; }
          const x = e.pageX - slider.offsetLeft;
          const scroll = x - startX;
          slider.scrollLeft = scrollLeft - scroll;
        });
        
        // Add the event listeners
        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);
        
    }, []);


    return(
        <div className="bg-2 bg-[#000625] bg-blend-color-dodge lg:bg-cover bg-no-repeat flex items-center lg:items-start flex-col justify-center relative pb-[10%]" id="characters">
            <div className='flex flex-col items-center font-semibold mt-16 justify-center w-full'>
                <h1 className="font-ROBOTECH text-white text-4xl lg:text-[3.5rem] max-w-[80vw] mb-6">CHARACTERS FOR THE GAME</h1>
                <h2 className="font-inter text-white max-w-[80%] lg:max-w-[50%] mb-8 font-bold">Each player is represented by an avatar in the game, and players could
interact with others through the avatar. The character can be customized according to personal preferences by.</h2>
               
            </div>
            <div id='parent' className="flex overflow-x-auto w-full lg:w-auto h-[525px] no-scrollbar cursor-grab items-end justify-center no-scrollbar">
                <img src={char1} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char2} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char3} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char4} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char5} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char6} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char7} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char9} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char10} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char11} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
                <img src={char12} alt="" className='w-[360px] h-[449px] z-10 hover:scale-110' />
,
                

            </div>
            <img src={cchars} alt="" className='h-[152px] absolute bottom-0 lg:bottom-16 right-0 left-0 mx-auto pointer-events-none ' />
            

        </div>
    );
}

export default Characters;