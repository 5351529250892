import "./Tokenomics.css";

import tokenomicsBase from "../assets/img/tokenomicsBase.webp";
import searchIcon from "../assets/img/searchIcon.png";

import bg1 from '../assets/img/tokenomicsBG1.webp'
import bg2 from '../assets/img/tokenomicsBG2.webp'

function Tokenomics() {
  return (
    <div className="bg-4 bg-[#000625] bg-blend-color-dodge bg-cover flex flex-col p-[10%] items-center justify-center overflow-hidden relative" id="tokenomics">
      <div className="flex flex-col items-center">
        <h1 className="text-white font-ROBOTECH leading-8 text-2xl lg:text-5xl mb-6">
          <span className="xmpText">LOSE YOURSELF</span> IN AN AMAZING, <br />
          EVOLVING TOKENOMICS!
        </h1>
        <a
          href="https://www.xmetapol.com/xmetapol_tokenomics.pdf"
          target="_blank"
          rel="noreferrer"
          className="max-w-[200px] px-4 py-1 bg-transparent text-white font-inter border-white border-[1px] rounded-3xl cursor-pointer hover:bg-gradient-to-r from-[#05F8ED] to-[#981E6E] hover:border-transparent"
        >
          Check our Tokenomics
        </a>
      </div>
      <div className="my-12 h-auto lg:h-[708px] w-[90vw] lg:w-[935px] pointer-events-none">
        <img src={tokenomicsBase} alt="" className="pointer-events-none"/>
      </div>
      <div className="lg:bg-frame-pattern h-auto lg:h-[314px] w-auto lg:w-[1058px] flex flex-col justify-center items-center">
        <div>
          <h2 className="font-ROBOTECH text-white text-3xl mt-4">
            CHECK OUR DOCUMENTS
          </h2>
          {/* <h3 className="text-[#979797] text-xs">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eget
            purus vel purus pulvinar porta nec in odio.
          </h3> */}
        </div>
        <div className="flex flex-col lg:flex-row justify-center mt-8">
          <a
            href="https://www.xmetapol.com/xmetapol_tokenomics.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <div className="group h-[131px] w-[219px] text-white hover:text-white bg-frame2-pattern trans flex flex-col  justify-center items-center brightness-90 hover:brightness-125 mr-0 lg:mr-6 mb-6 lg:mb-0">
              <h1 className="font-ROBOTECH text-2xl">TOKENOMICS</h1>
              <img
                src={searchIcon}
                alt=""
                className="hidden group-hover:block trans"
              />
            </div>
          </a>

          <a href="https://www.xmetapol.com/xmetapol_whitepaper.pdf"
            target="_blank"
            rel="noreferrer">
            <div className="group h-[131px] w-[219px] text-white hover:text-white trans bg-frame2-pattern flex flex-col  justify-center items-center brightness-90 hover:brightness-125 mr-0 lg:mr-6 mb-6 lg:mb-0">
              <h1 className="font-ROBOTECH text-2xl">WHITEPAPER</h1>
              <img
                src={searchIcon}
                alt=""
                className="hidden group-hover:block trans"
              />
            </div>
          </a>

          <a href="https://www.xmetapol.com/xmetapol_roadmap.pdf"
            target="_blank"
            rel="noreferrer"
            >
            <div className="group h-[131px] w-[219px] text-white hover:text-white trans bg-frame2-pattern flex  flex-col justify-center items-center brightness-90 hover:brightness-125 mr-0 lg:mr-6 mb-6 lg:mb-0">
              <h1 className="font-ROBOTECH text-2xl">ROADMAP</h1>
              <img
                src={searchIcon}
                alt=""
                className="hidden group-hover:block trans"
              />
            </div>
          </a>
          <a href="https://www.xmetapol.com/xmetapol_tokenomics.pdf"
            target="_blank"
            rel="noreferrer"
            >
            <div className="group h-[131px] w-[219px] text-white bg-frame2-pattern flex  flex-col justify-center items-center trans brightness-90 hover:brightness-125">
              <h1 className="font-ROBOTECH text-2xl">PRIVACY POLICY</h1>
              <img
                src={searchIcon}
                alt=""
                className="hidden group-hover:block trans"
              />
            </div>
          </a>
        </div>
      </div>

      <img src={bg1} alt="" className="mix-blend-color-dodge hidden lg:block absolute top-20 left-0 h-96"/>
      <img src={bg2} alt="" className="mix-blend-color-dodge hidden lg:block absolute bottom-0 right-0 h-96"/>

    </div>
  );
}

export default Tokenomics;
