import girl2 from "../../assets/img/frame2girl.webp";
import tree from "../../assets/img/frame2tree.webp";
import checkTrailerM from '../../assets/img/checkTrailerM.png'

function Frame2() {
  return (
    <div className="bg-[#1E0C0D]">
      <div className="h-[164px] bg-[#1E0C0D] w-full hidden md:block"></div>
      <div
        className="bg-frame2-bg h-[700px] lg:h-[1062px] bg-[length:800px_700px] lg:bg-cover bg-center lg:bg-top hidden md:block"
        id="trailer"
      >
        <div className="h-full w-full relative">
          <h1 className="font-ROBOTECH text-white text-5xl leading-7 absolute top-10 lg:top-28 left-0 right-0 mx-auto ">
            CHECK OUR TRAILER
          </h1>
          <iframe width="730" height="410" className="absolute left-[376px] top-[210px]" src="https://www.youtube.com/embed/xkXXtK6OEow" title="xMetapol - Welcome to The Future - Official Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <img
          
            src={girl2}
            alt=""
            className="hidden lg:block absolute bottom-2 right-0 h-[871px] w-[412px]"
          />
          <img
            src={tree}
            alt=""
            className="hidden lg:block absolute bottom-16 -left-[240px]"
          />
          
        </div>
      </div>
      <div className="flex flex-col items-center justify-center py-[20%] md:hidden bg-2 bg-no-repeat bg-blend-color-dodge"> 
          <h1 className="font-ROBOTECH text-white text-6xl mb-10">CHECK OUR TRAILER</h1>
          <iframe width="276" height="356" src="https://www.youtube.com/embed/xkXXtK6OEow" className="" title="xMetapol - Welcome to The Future - Official Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </div>
  );
}

export default Frame2;
