import NFT from "../components/NFT";

import bg1 from '../assets/img/metapolandBG1.webp'
import bg2 from '../assets/img/metapolandBG2.webp'


function NFTMetapoland() {
    return(
        <div className="bg-[#000625] py-[10%] overflow-hidden bg-1 bg-blend-color-dodge bg-no-repeat bg-cover bg-top flex items-center justify-center relative" id="metapoland">
            <div className="flex flex-col justify-center items-center px-6">
                {/* ----------------- Header --------------- */}

                <div className="max-w-[70vw] flex items-center justify-center flex-col mb-16">
                    <h1 className="text-8xl text-white font-ROBOTECH mb-8">NFT - METAPOLANDS</h1>

                    <h3 className="text-white font-inter mb-6 font-semibold">MetaPolands, the non-fungible parcels in which the virtual world is divided; Plots can be upgraded over time using a variety of resources and crafting ingredients that can be found when playing the game. Also, XMP used to buy goods and services in the virtual world creates utility value for the token.</h3>
                  
                </div>

                {/* --------------------- Cards ----------------- */}
                <div className=" flex-col lg:flex-row flex-wrap mb-0 lg:mb-16 hidden lg:flex">
                    <NFT></NFT>
                    <NFT className=""></NFT>
                    <NFT className="hidden lg:block"></NFT>
                </div>
                <div className="flex flex-col lg:flex-row flex-wrap mb-0 lg:mb-16">
                    <NFT className="hidden lg:block"></NFT>
                    <NFT className="hidden lg:block"></NFT>
                    <NFT className="hidden lg:block"></NFT>
                </div>

                {/* ----------------------- sub text ------------------- */}

                <div className="mt-16 max-w-[70vw]">
                    <h2 className="text-white font-inter font-semibold">A vital feature of the X-MetaPol token is its native NFT marketplace, which acts as a hub for presidents.Exclusive and unique NFTs designed by our experienced designers can be purchased by anyone who wishes to own a piece of these special collections.</h2>
                </div>
                <div className="mt-4">
                    <div className="max-w-[200px] px-5 py-1 bg-transparent text-white font-inter border-white border-[1px] rounded-3xl cursor-pointer hover:bg-gradient-to-r from-[#05F8ED] to-[#981E6E] hover:border-transparent transition-all">More NFTs</div>
                </div>



                <img src={bg1} alt=""  className="mix-blend-color-dodge absolute top-20 left-0 h-96"/>
                <img src={bg2} alt=""  className="mix-blend-color-dodge absolute bottom-20 right-0 h-96"/>



            </div>
        </div>
    );
}

export default NFTMetapoland;