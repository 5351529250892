import team3 from '../assets/img/team3.webp';

import diamond from '../assets/img/diamond2.png';

import ig from '../assets/img/ig.png';
import mail from '../assets/img/mail.png';
import linkedin from '../assets/img/linkedin.png';

import rect from '../assets/img/team5.png'
import emrahRect from '../assets/img/emrahRect.png';
import dennisRect from '../assets/img/dennisRect.webp'
import serhatRect from '../assets/img/serhatRect.webp'
import sametRect from '../assets/img/sametRect.png'
import sefaRect from '../assets/img/sefaRect.png'



import { useState } from 'react';

import './Team.css';

function Test() {

    const EmrahInf = {
        name: "EMRAH VURAL",
        title: "Founder of X-Metapol",
        description: "Developing AI, Metaverse and Web3 games and digital assets. Blockchain and cryptocurrency enthusiast.",
        linkedin: "https://www.linkedin.com/in/emrah-vural-8671b919a",
        instagram: "",
        mail: "",
        photo: `${process.env.PUBLIC_URL}/assets/emrah.webp`
    }

    const DennisInf = {
        name: "DENNIS ZNAMENSKIY",
        title: "Business Development",
        description: "SPAC professional, I participate as speaker at international blockchain events, advising fintech companies (PrismCoin, Bibox, IndexChain, Demole.io). I have demonstrated skills in management of quantitative teams, algorithmic strategies, hedge fund and startups",
        linkedin: "https://www.linkedin.com/in/denisznamenskiy",
        instagram: "",
        mail: "",
        photo: `${process.env.PUBLIC_URL}/assets/dennis.png`
    }
    
    const SerhatInf = {
        name: "SERHAT YANAR",
        title: "Advisor",
        description: "Co-founder at CheersLand launchpad,project lead at Bluewheel Mining Club & advisor at multiple blockchain projects which needs contact with venture capitals, launchpads and marketing teams.",
        linkedin: "https://www.linkedin.com/in/serhatyanar",
        instagram: "",
        mail: "",
        photo: `${process.env.PUBLIC_URL}/assets/serhat.webp`
    }
    const SametInf = {
        name: "SAMED KARAKUS",
        title: "UI/UX & NFT Designer",
        description: "Experienced UI/UX & NFT Designer specializing in visually stunning and user-friendly interfaces for blockchain, NFTs, and decentralized applications. Passionate about understanding the potential impact of blockchain on design and pushing the boundaries of user experience.",
        linkedin: "https://www.linkedin.com/in/samedkarakusceng/",
        instagram: "https://www.instagram.com/karakus.samed/",
        mail: "samedkarakus.ceng@gmail.com",
        photo: `${process.env.PUBLIC_URL}/assets/samet.png`
    }
    const SefaInf = {
        name: "SEFA BASER",
        title: "Chief Financial Officer",
        description: "Chief Financial Officer & Co-Founder of amagroups.pro company Experienced with administrative operations in the field of Marketing.",
        linkedin: "https://www.linkedin.com/in/sefa-basar-337876221/",
        instagram: "",
        mail: "",
        photo: `${process.env.PUBLIC_URL}/assets/sefa.png`
    }

    const [person, setPerson] = useState({
        name: "EMRAH VURAL",
        title: "Founder of X-Metapol",
        description: "Blockchain enthusiast, Project management and strategist. High experience involved in various project attachments for more than 7 years. International bussines consultant. Archaeologist. ",
        linkedin: "https://www.linkedin.com/in/emrah-vural-8671b919a",
        instagram: "",
        mail: "",
        photo: `${process.env.PUBLIC_URL}/assets/emrah.webp`
    });


   






    return(
        <div className=" bg-6 bg-cover flex flex-col lg:flex-row bg-[#000625] bg-blend-color-dodge relative p-[10%] justify-around" id="team">
            <img src={team3} alt=""  className="hidden lg:block absolute bottom-16 right-4 h-80"/>
            
            <div className="flex flex-col items-center mb-80 justify-center">
               
                <div className='bg-team-frame3 h-[290px] w-[300px] lg:h-[462px] lg:w-[506px] flex items-center z-10 relative justify-center bg-no-repeat bg-contain'>
                    <img src={person.photo} alt="" className='object-cover cutCorners h-[280px] lg:h-[450px] w-[290px] lg:w-[495px] self-start lg:self-center z-0'/>
                    <img src={diamond} alt="" className='absolute right-0 left-0 mx-auto -bottom-16 lg:-bottom-24 h-20 lg:h-auto diamondAnim2 lg:diamondAnim'/>
                </div>

                <div className='flex lg:flex-row flex-col relative w-full lg:w-[480px] h-full lg:h-[225px] mt-6 lg:mt-20 ml-0 lg:ml-6 items-center'>
                    <img src={emrahRect} alt="" onClick={e => {setPerson(EmrahInf)}} className='absolute -bottom-72 left-20 lg:left-0 lg:bottom-0    h-[133px] w-[133px]'/>
                    <img src={dennisRect} alt="" onClick={e => {setPerson(DennisInf)}} className='absolute top-20 left-0  lg:left-20 lg:top-0   h-[133px] w-[133px]'/>
                    <img src={serhatRect} alt="" onClick={e => {setPerson(SerhatInf)}} className='absolute  -bottom-52 left-40 lg:left-40 lg:bottom-0 h-[133px] w-[133px] '/>
                    <img src={sametRect} alt=""  onClick={e => {setPerson(SametInf)}} className='absolute  top-60 left-0 lg:left-60 lg:top-0  h-[133px] w-[133px]'/>
                    <img src={sefaRect} alt=""  onClick={e => {setPerson(SefaInf)}} className='absolute  -bottom-[23.3rem] left-40 lg:left-80 lg:bottom-0   h-[133px] w-[133px]'/>
                </div>
                
            </div>
            <div className='flex flex-col basis-full lg:basis-[40%] mt-20 lg:mt-0 items-center'>
                <h1 className='text-white text-6xl font-ROBOTECH font tracking-widest text-center lg:text-left'>EXECUTIVE TEAM</h1>
                <h2 className="font-inter text-[#A7A7A7] text-left py-8">Meet our awesome team!</h2>
                <div className='bg-team-frame2 lg:bg-team-frame1 bg-contain bg-no-repeat h-[489px] w-[307px] lg:h-[305px] lg:w-[487px] px-16 py-12 flex flex-col text-left'>
                    <h1 className="text-white font-ROBOTECH text-3xl">{person.name}</h1>
                    <h2 className='text-[#A3A3A3]'>{person.title}</h2>
                    <h3 className='text-[#A3A3A3] text-sm mt-4 lg:mt-2'>{person.description}</h3>
                    <div className="flex flex-row gap-2 mt-6">
                        <a href={person.instagram} target="_blank" rel="noreferrer">
                            <img src={ig} alt="" />
                        </a>
                       <a href={person.linkedin} target="_blank" rel="noreferrer">
                            <img src={linkedin} alt="" />
                       </a>
                       <a href={person.mail} target="_blank" rel="noreferrer">
                            <img src={mail} alt="" />
                       </a>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Test;