import tree1 from '../assets/img/tree1.webp'
import tree2 from '../assets/img/tree2.webp'
import logoWhite from '../assets/img/3dlogo4.png'
import badge1 from '../assets/img/badge1.png'
import badge2 from '../assets/img/badge2.png'
import badge3 from '../assets/img/badge3.png'
import "./Utility.css";
import { useEffect } from 'react'

function Utility() {



    return(
        <div className="bg-3 bg-cover bg-blend-color-dodge h-auto bg-[#000625] relative py-32" id="why">
            <img src={tree1} alt="" className='absolute bottom-0 right-0 h-[80vh] mix-blend-color-dodge'/>
            <img src={tree2} alt="" className='absolute bottom-0 left-0 h-[80vh] mix-blend-color-dodge '/>
            <div className='h-screen flex flex-col items-center justify-around '>
                <h1 className='font-ROBOTECH text-4xl lg:text-5xl text-white'>WHY X-METAPOL TOKEN?</h1>
                
                <div className="flex overflow-x-auto w-full lg:w-auto">
                    <div className='ml-auto utilityBox inline-flex flex-col text-xs lg:text-s shrink-0 relative items-center w-[360px] h-[422px] p-8 mr-2 lg:mr-8 mt-12'>
                        <img src={logoWhite} alt="" className="m-10"/>
                        <h2 className='text-white font-inter font-semibold'>X-MetaPol has a 100% player-owned, crypto and real money economy. Rather than selling game items or copies, the developers of the game focus on growing the player to player economy.</h2>
                        <img src={badge2} alt="" className="absolute left-0 right-0 mx-auto -bottom-12 w-24"/>
                    </div>
                    <div className='utilityBox inline-flex flex-col text-xs lg:text-s shrink-0 relative items-center w-[360px] h-[422px] p-8 mr-2 lg:mr-8 mb-24'>
                        <img src={logoWhite} alt="" className="m-10"/>
                        <h2 className='text-white font-inter font-semibold'>X-MetaPol is a new type of game that rewards players for the time and effort they spend both playing the game and growing the ecosystem.</h2>
                        <img src={badge1} alt="" className="absolute left-0 right-0 mx-auto -bottom-12 w-24"/>
                    </div>
                    <div className='mr-auto utilityBox inline-flex flex-col text-xs lg:text-s shrink-0 relative items-center w-[360px] h-[422px] p-8 mt-12'>
                        <img src={logoWhite} alt="" className="m-10"/>
                        <h2 className='text-white font-inter font-semibold'>Game resources and items are tokenized, meaning they can be sold to anyone, anywhere on open peer-to-peer markets.
X-MetaPol population growth is a major factor within the XMP ecosystem. At any given moment, there exists some ideal inflation rate that allows us to grow to our maximum potential.</h2>
                        <img src={badge3} alt="" className="absolute left-0 right-0 mx-auto -bottom-12 w-24 "/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Utility;